// Entry point for the build script in your package.json

import * as ActiveStorage from "@rails/activestorage"

ActiveStorage.start()

import "./controllers"
import * as bootstrap from 'bootstrap'
import '@fortawesome/fontawesome-pro/js/all'
import "./greenhats-icon"
import Swal from 'sweetalert2'
import "trix"
import "@rails/actiontext"


window.Swal = Swal
window.bootstrap = bootstrap

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  customClass: {
    popup: 'colored-toast'
  },
  iconColor: 'white',
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

window.Toast = Toast

document.addEventListener("DOMContentLoaded", function(event) {
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
});

document.addEventListener('DOMContentLoaded', function() {
  var swal_popup = document.getElementById("swal_popup");
  if (document.getElementById("swal_popup") != null){
    swal_popup.remove();
  }
  // remove all modal objects
  let modals = document.querySelectorAll('.modal');
  modals.forEach(function(modal) {
      modal.remove();
  });
});

// custom swal rails confirm
/*
let __SkipConfirmation = false;

Rails.confirm = function (message, element) {
  if (__SkipConfirmation) {
    return true;
  }
  function onConfirm() {
    __SkipConfirmation = true
    element.click()
    __SkipConfirmation = false
  }
  Swal.fire({
    html: `${message}`,
    icon: 'question',
    customClass: {
      confirmButton: 'btn btn-success me-3',
      cancelButton: 'btn btn-secondary text-white'
    },
    buttonsStyling: false,
    showCancelButton: true,
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    }
  })
  return false;
};
*/
